import Glide from '../../node_modules/@glidejs/glide/dist/glide'
import '../../node_modules/@glidejs/glide/src/assets/sass/glide.core.scss'
// import '../../node_modules/@glidejs/glide/src/assets/sass/glide.theme'

document.addEventListener('DOMContentLoaded', function () {
	const sliders = document.querySelectorAll('.glide')

	if (sliders.length) {
		sliders.forEach((item, id) => {
			let glide = new Glide(item, {
				perView: 1,
			})

			let bullets = item.querySelectorAll('.glide__bullet')

			bullets.forEach((item) =>
				item.addEventListener('click', () => {
					glide.go(item.dataset.glideDir)
				})
			)

			glide.on('run', function () {
				bullets.forEach((item) => {
					item.classList.remove('glide__bullet--active')
				})
				bullets[glide.index].classList.add('glide__bullet--active')
			})

			glide.mount()
		})
	}
})
