import Gumshoe from 'gumshoejs'
import { addBackToTop } from 'vanilla-back-to-top'

let mobileMenuButton = document.querySelector('.hamburger')
let nav = document.querySelector('#nav')
let langs = document.querySelector('#langs')

mobileMenuButton.addEventListener('click', (event) => {
	event.preventDefault()
	if (event.target.classList.contains('hamburger-box')) {
		event.target.parentNode.classList.toggle('is-active')
		document.body.classList.toggle('non-scrollable')
		nav.classList.toggle('nav--open')
		langs.classList.toggle('langs--open')
		return
	} else if (event.target.classList.contains('hamburger-inner')) {
		event.target.parentNode.parentNode.classList.toggle('is-active')
		document.body.classList.toggle('non-scrollable')
		nav.classList.toggle('nav--open')
		langs.classList.toggle('langs--open')
		return
	}
	event.target.classList.toggle('is-active')
	document.body.classList.toggle('non-scrollable')
	nav.classList.toggle('nav--open')
	langs.classList.toggle('langs--open')
})

let menuLinks = document.querySelectorAll('.nav__link')
let spyMenu = new Gumshoe('#nav .nav__link', {
	// Active classes
	navClass: 'nav__item--active', // applied to the nav list item
	contentClass: 'active', // applied to the content

	// Nested navigation
	nested: false, // if true, add classes to parents of active link
	nestedClass: 'active', // applied to the parent items

	// Offset & reflow
	offset: window.innerWidth < 1024 ? -60 : 95, // how far from the top of the page to activate a content area
	reflow: false, // if true, listen for reflows

	// Event support
	events: false, // if true, emit custom events
})
menuLinks.forEach((item) => {
	item.addEventListener('click', (e, i) => {
		e.preventDefault()
		if (nav.classList.contains('nav--open') && document.body.classList.contains('non-scrollable')) {
			mobileMenuButton.classList.toggle('is-active')
			nav.classList.toggle('nav--open')
			document.body.classList.toggle('non-scrollable')
			langs.classList.toggle('langs--open')
		}
		let linkID = document.querySelector(`${e.target.getAttribute('href')}`)
		const gapFromBlockId = 88
		const top = linkID.offsetTop - gapFromBlockId
		window.scrollTo({
			top,
			left: 0,
			behavior: 'smooth',
		})
	})
})

addBackToTop({
	diameter: 48,
	backgroundColor: '#063',
	textColor: '#fff',
})
